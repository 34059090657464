import React from "react";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";

import { useVisionUIController, setOpenCompose } from "context";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";

import { FaUser, FaThumbsUp } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { IoCart } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdUploadFile } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { MdDelete } from "react-icons/md";

// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "layouts/pages/users/reports/data/tableData";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiSelect from "components/VuiSelect";
import { getUserList } from "services/mailService";
import { sendMail } from "services/mailService";
import { getUserFiles } from "services/mailService";

import { CardActions, CardContent } from "@mui/material";
import Swal from "sweetalert2";

const Compose = () => {
  const [controller, dispatch] = useVisionUIController();
  const { openCompose, mailForwardTrack } = controller;
  const [dialogBox, setDialogBox] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [recipients, setRecipients] = useState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [upload, setUpload] = useState(false);
  const [userDocuments, setUserDocuments] = useState(false);
  const [billOfLading, setBillOfLading] = useState([]);
  const [proformaInvoice, setProformaInvoice] = useState([]);
  const [commercialInvoice, setCommercialInvoice] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [quotation, setQuotation] = useState([]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    fetchUserFiles();
  }, [openCompose]);

  const fetchUserFiles = async () => {
    const resp = await getUserFiles();
    // console.log("files", resp);

    if (
      resp.billOflading.length === 0 &&
      resp.proformaInvoice.length === 0 &&
      resp.commercialInvoice.length === 0 &&
      resp.purchaseOrder.length === 0 &&
      resp.quotation.length === 0
    ) {
      setUserDocuments(false);
    } else {
      setUserDocuments(true);
      setBillOfLading(resp.billOflading);
      setProformaInvoice(resp.proformaInvoice);
      setCommercialInvoice(resp.commercialInvoice);
      setPurchaseOrder(resp.purchaseOrder);
      setQuotation(resp.quotation);
    }
  };

  // console.log(
  //   "lengths",
  //   billOfLading,
  //   proformaInvoice,
  //   commercialInvoice,
  //   purchaseOrder,
  //   quotation
  // );

  const dates = (time) => {
    var d = new Date(time);
    var time = `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`;

    return time;
  };

  const handleCompose = () => setOpenCompose(dispatch, !openCompose);

  const findUsers = (e) => {
    getUserList(e).then((response) => {
      console.log("list", response);
      const resp = response.map((data) => {
        data.label = `${data.userEmail}`;
        data.value = `${data.userID}`;
        return data;
      });
      setSearchedUsers(resp);
    });
  };

  const handleChangeInput = (name, value) => {
    if (name === "recipients") {
      setRecipients(value);
    } else if (name === "subject") {
      setSubject(value);
    } else if (name === "message") {
      setMessage(value);
    }
  };

  // console.log("rec", recipients);

  // console.log("ddddddddddddddddd", mailForwardTrack);

  const handleSend = () => {
    let onBlockChainDocs = [];

    for (let item of selectedDocuments) {
      console.log("name", item);
      onBlockChainDocs.push({
        name: item.document_type,
        hash: item.onBlockChainHash,
      });
    }

    let composeData = {
      reciverUserID: recipients.userID,
      subject: subject,
      message: message,
      onBlockChainDocs: onBlockChainDocs,
      onBlockChainTracking: mailForwardTrack,
    };

    console.log("composeData", composeData);

    Swal.fire({
      position: "center",
      title: "Sending",
      showConfirmButton: false,
      allowOutsideClick: false,

      didOpen: () => {
        Swal.showLoading();

        sendMail(composeData).then((response) => {
          console.log("response>>", response);

          if (response.success === true) {
            Swal.close();
          } else {
            Swal.fire({
              title: "Sending Failed",
              text: "Please try again",
              icon: "error",
              showConfirmButton: true,
            });
          }
        });
      },
    }).then(() => {
      Swal.fire({
        title: "Sent successfully",
        icon: "success",
        showConfirmButton: true,
      }).then(() => {
        window.location.reload();
      });
    });
  };

  const openUpload = () => {
    setUpload(true);
  };

  const uploadClose = () => {
    setUpload(false);
  };

  let newSelectedDocuments = [...selectedDocuments];

  const handleSelect = (document, idx) => {
    // console.log("document", document);
    // console.log("index", idx);
    // console.log("indexOfDocument", newSelectedDocuments.indexOf(document.id));
    // let newSelectedDocuments = [...selectedDocuments];

    if (newSelectedDocuments.includes(document)) {
      newSelectedDocuments.splice(
        newSelectedDocuments.findIndex((x) => x.id == document.id),
        1
      );
      // console.log("inside", newSelectedDocuments);
      setSelectedDocuments(newSelectedDocuments);
    } else {
      console.log("added>>>>>>>>>>");
      newSelectedDocuments.push(document);
      setSelectedDocuments(newSelectedDocuments);
      // setUpload(false);
    }
  };

  const documentDelete = (idx) => {
    // let newSelectedDocuments = [...selectedDocuments];
    newSelectedDocuments.splice(idx, 1);
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleAttach = () => {
    ////attach

    setUpload(false);
  };

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { background: "rgb(255, 255, 255)" } }}
        fullWidth={true}
        maxWidth="md"
        open={openCompose}
        onClose={() => handleCompose()}
      >
        <VuiBox>
          <DialogTitle>
            <VuiTypography color="text" fontSize="1rem">
              New Message
            </VuiTypography>
          </DialogTitle>
          <DialogContent dividers>
            <VuiBox>
              <VuiBox>
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  {/* <VuiTypography color="text" fontSize="1rem">
                  To
                </VuiTypography> */}
                  <VuiSelect
                    placeholder="To"
                    options={searchedUsers}
                    value={recipients}
                    onInputChange={(e) => findUsers(e)}
                    onChange={(e) => handleChangeInput("recipients", e)}
                  />

                  {/* <VuiInput
                sx={{ border: "none", borderBottom: "1px solid white", borderRadius: "0px" }}
                placeholder="Recipients"
              /> */}
                </VuiBox>
              </VuiBox>

              <VuiBox sx={{ marginBottom: "1rem" }}>
                <VuiInput
                  name="subject"
                  placeholder="Subject"
                  onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                />
              </VuiBox>
              <VuiBox sx={{ marginBottom: "1rem" }}>
                <VuiTypography color="text" fontSize="1rem">
                  Message
                </VuiTypography>
                <VuiInput
                  name="message"
                  multiline
                  rows={4}
                  placeholder="Type here"
                  onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                />
              </VuiBox>
            </VuiBox>
            <VuiBox sx={{ marginBottom: "1rem" }}>
              <VuiBox>
                {selectedDocuments.map((document, idx) => (
                  <VuiBox
                    key={idx}
                    sx={{
                      backgroundColor: "#e6e6e6",
                      marginBottom: "0.5rem",
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiBox marginLeft="1rem">
                      <VuiTypography color="text" fontSize="1rem">
                        {document.document_type}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox marginRight="1rem">
                      <IconButton size="small" onClick={() => documentDelete(idx)}>
                        <MdDelete />
                      </IconButton>
                    </VuiBox>
                  </VuiBox>
                ))}
              </VuiBox>

              {/* <VuiTypography color="text" fontSize="1rem">
                Attach Documents
              </VuiTypography> */}

              {/* <VuiButton
                sx={{ marginRight: "1rem" }}
                color="info"
                variant="gradient"
                onClick={() => openUpload()}
              >
                <MdUploadFile />
                Upload
              </VuiButton> */}
            </VuiBox>
          </DialogContent>

          <DialogActions>
            <VuiButton color="info" variant="gradient" onClick={() => openUpload()}>
              Attach files
            </VuiButton>

            <VuiButton color="info" variant="gradient" onClick={() => handleSend()}>
              <IoIosSend />
              Send
            </VuiButton>
          </DialogActions>
        </VuiBox>
      </Dialog>

      <Dialog
        sx={{ "& .MuiDialog-paper": { background: "rgb(255, 255, 255)" } }}
        fullWidth={true}
        maxWidth="lg"
        open={upload}
        onClose={() => uploadClose()}
      >
        <VuiBox>
          <DialogTitle>
            <VuiTypography color="text" fontSize="1rem">
              Documents
            </VuiTypography>
          </DialogTitle>
          <DialogContent dividers>
            <VuiBox sx={{ marginBottom: "2rem" }}>
              {userDocuments === false && (
                <VuiBox sx={{ textAlign: "center" }}>
                  <VuiTypography color="text" fontSize="1rem" fontWeight="bold">
                    You have currently no documents available
                  </VuiTypography>
                </VuiBox>
              )}

              {billOfLading.length > 0 && (
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiBox>
                    <VuiTypography color="text" fontSize="1rem">
                      Bill of Lading
                    </VuiTypography>
                  </VuiBox>

                  <VuiBox sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                    {billOfLading &&
                      billOfLading.map((document, idx) => (
                        <VuiBox key={idx} sx={{ borderRadius: "5%", cursor: "pointer" }}>
                          <Card
                            onClick={() => handleSelect(document, idx)}
                            // raised={true}
                            sx={{
                              background: selectedDocuments.includes(document)
                                ? "#dd8888"
                                : "#f2d1d1",
                              padding: 0,
                              borderRadius: "5%",
                              width: "7rem",
                              height: "7rem",
                              // textAlign: "center",
                              // "&:hover": {
                              //   background: "grey",
                              // },
                            }}
                          >
                            <CardContent>
                              <VuiTypography
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color="text"
                                fontSize="0.6rem"
                              >
                                {document.unique_consignment_reference}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.6rem">
                                {dates(document.date)}
                              </VuiTypography>
                              {/* <VuiTypography color="text" fontSize="0.7rem">
                                {document.port_of_landing}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.7rem">
                                {document.port_of_discharge}
                              </VuiTypography> */}
                            </CardContent>
                          </Card>
                          {/* <VuiBox>
                            <VuiButton
                              sx={{ borderRadius: "0 0 6px 6px" }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              onClick={() => handleSelect(document)}
                            >
                              Select
                            </VuiButton>
                          </VuiBox> */}
                        </VuiBox>
                      ))}
                  </VuiBox>
                </VuiBox>
              )}

              {proformaInvoice.length > 0 && (
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiBox>
                    <VuiTypography color="text" fontSize="1rem">
                      Proforma Invoice
                    </VuiTypography>
                  </VuiBox>

                  <VuiBox sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                    {proformaInvoice &&
                      proformaInvoice.map((document, idx) => (
                        <VuiBox key={idx} sx={{ borderRadius: "5%", cursor: "pointer" }}>
                          <Card
                            onClick={() => handleSelect(document, idx)}
                            raised={true}
                            sx={{
                              background: selectedDocuments.includes(document)
                                ? "#61b8a8"
                                : "#b8dfd8",
                              padding: 0,
                              borderRadius: "5%",
                              width: "7rem",
                              height: "7rem",
                              // textAlign: "center",
                              // "&:hover": {
                              //   background: "grey",
                              // },
                            }}
                          >
                            <CardContent>
                              <VuiTypography
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color="text"
                                fontSize="0.6rem"
                              >
                                {document.invoiceNumber}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.6rem">
                                {dates(document.dateOfInvoice)}
                              </VuiTypography>
                              {/* <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfLoading}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfDischarge}
                              </VuiTypography> */}
                            </CardContent>
                          </Card>
                          {/* <VuiBox>
                            <VuiButton
                              name="Proforma Invoice"
                              sx={{ borderRadius: "0 0 6px 6px" }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              onClick={(e) => handleSelect(document)}
                            >
                              Select
                            </VuiButton>
                          </VuiBox> */}
                        </VuiBox>
                      ))}
                  </VuiBox>
                </VuiBox>
              )}

              {commercialInvoice.length > 0 && (
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiBox>
                    <VuiTypography color="text" fontSize="1rem">
                      Commercial Invoice
                    </VuiTypography>
                  </VuiBox>

                  <VuiBox sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                    {commercialInvoice &&
                      commercialInvoice.map((document, idx) => (
                        <VuiBox key={idx} sx={{ borderRadius: "5%", cursor: "pointer" }}>
                          <Card
                            onClick={() => handleSelect(document, idx)}
                            raised={true}
                            sx={{
                              background: selectedDocuments.includes(document)
                                ? "#9ecb80"
                                : "#d6e9c9",
                              padding: 0,
                              borderRadius: "5%",
                              width: "7rem",
                              height: "7rem",
                              // textAlign: "center",
                              // "&:hover": {
                              //   background: "grey",
                              // },
                            }}
                          >
                            <CardContent>
                              <VuiTypography
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color="text"
                                fontSize="0.6rem"
                              >
                                {document.invoiceNumber}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.6rem">
                                {dates(document.dateOfInvoice)}
                              </VuiTypography>
                              {/* <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfLoading}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfDischarge}
                              </VuiTypography> */}
                            </CardContent>
                          </Card>
                          {/* <VuiBox>
                            <VuiButton
                              sx={{ borderRadius: "0 0 6px 6px" }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              onClick={() => handleSelect(document)}
                            >
                              Select
                            </VuiButton>
                          </VuiBox> */}
                        </VuiBox>
                      ))}
                  </VuiBox>
                </VuiBox>
              )}

              {purchaseOrder.length > 0 && (
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiBox>
                    <VuiTypography color="text" fontSize="1rem">
                      Purchase Order
                    </VuiTypography>
                  </VuiBox>

                  <VuiBox sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                    {purchaseOrder &&
                      purchaseOrder.map((document, idx) => (
                        <VuiBox key={idx} sx={{ borderRadius: "5%", cursor: "pointer" }}>
                          <Card
                            onClick={() => handleSelect(document, idx)}
                            raised={true}
                            sx={{
                              background: selectedDocuments.includes(document)
                                ? "#90c0d5"
                                : "#daeaf1",
                              padding: 0,
                              borderRadius: "5%",
                              width: "7rem",
                              height: "7rem",
                              // textAlign: "center",
                              // "&:hover": {
                              //   background: "grey",
                              // },
                            }}
                          >
                            <CardContent>
                              <VuiTypography
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color="text"
                                fontSize="0.6rem"
                              >
                                {document.invoiceNumber}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.6rem">
                                {dates(document.dateOfPurchaseOrder)}
                              </VuiTypography>
                              {/* <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfLoading}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfDischarge}
                              </VuiTypography> */}
                            </CardContent>
                          </Card>
                          {/* <VuiBox>
                            <VuiButton
                              sx={{ borderRadius: "0 0 6px 6px" }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              onClick={() => handleSelect(document)}
                            >
                              Select
                            </VuiButton>
                          </VuiBox> */}
                        </VuiBox>
                      ))}
                  </VuiBox>
                </VuiBox>
              )}

              {quotation.length > 0 && (
                <VuiBox>
                  <VuiBox>
                    <VuiTypography color="text" fontSize="1rem">
                      Quotation
                    </VuiTypography>
                  </VuiBox>

                  <VuiBox sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                    {quotation &&
                      quotation.map((document, idx) => (
                        <VuiBox key={idx} sx={{ borderRadius: "5%", cursor: "pointer" }}>
                          <Card
                            onClick={() => handleSelect(document, idx)}
                            raised={true}
                            sx={{
                              background: selectedDocuments.includes(document)
                                ? "#ffbf80"
                                : "#ffeddb",
                              padding: 0,
                              borderRadius: "5%",
                              width: "7rem",
                              height: "7rem",
                              // textAlign: "center",
                              // "&:hover": {
                              //   background: "grey",
                              // },
                            }}
                          >
                            <CardContent>
                              <VuiTypography
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color="text"
                                fontSize="0.6rem"
                              >
                                {document.quoteNumber}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.6rem">
                                {dates(document.date)}
                              </VuiTypography>
                              {/* <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfLoading}
                              </VuiTypography>
                              <VuiTypography color="text" fontSize="0.7rem">
                                {document.portOfDischarge}
                              </VuiTypography> */}
                            </CardContent>
                          </Card>
                          {/* <VuiBox>
                            <VuiButton
                              sx={{ borderRadius: "0 0 6px 6px" }}
                              fullWidth
                              color="info"
                              variant="gradient"
                              onClick={() => handleSelect(document)}
                            >
                              Select
                            </VuiButton>
                          </VuiBox> */}
                        </VuiBox>
                      ))}
                  </VuiBox>
                </VuiBox>
              )}
            </VuiBox>
          </DialogContent>

          <DialogActions>
            <VuiButton onClick={() => handleAttach()} color="info" variant="gradient">
              {/* <IoIosSend /> */}
              Attach
            </VuiButton>
          </DialogActions>
        </VuiBox>
      </Dialog>
    </>
  );
};

export default Compose;
