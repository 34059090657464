import { useState, useEffect } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

import Validations from "shared/validations";
import ValidationService from "shared/validationService";

// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images

import illustrationImage from "assets/images/illustration-image.png";

import Swal from "sweetalert2";
import { resetPassword } from "services/userService";

function ResetPassword() {
  const { id } = useParams();
  const [errorMsg, setErrorMsg] = useState({
    password: { errorMsg: "" },
    confirmPassword: { errorMsg: "" },
  });

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isShowPassword2, setIsShowPassword2] = useState(true);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.signUpValidation[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleResetInput = (name, value) => {
    if (name === "password") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleReset = async () => {
    let errorLengthNewPassword = customValidations("password", newPassword);
    let errorLengthConfirmPassword = customValidations("confirmPassword", confirmPassword);

    if (errorLengthNewPassword !== 1 && errorLengthConfirmPassword !== 1) {
      console.log("Alll okkkkkkk");
    } else {
      console.log(errorLengthNewPassword, errorLengthConfirmPassword, "passord errrrorrrrr");
      return;
    }

    const passData = {
      password: newPassword,
      cpassword: confirmPassword,
      token: id,
    };

    const res = await resetPassword(passData);

    if (res.success === true) {
      Swal.fire("", "Password reset successfully", "success");
    } else {
      Swal.fire("", "Password reset failed", "error");
    }

    console.log("reset", res);
  };

  const handleShowPassword = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword(type);
  };

  const handleShowPassword2 = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword2(type);
  };

  return (
    <CoverLayout
      // logo={NewBitnauticLogo}
      title="Welcome to BitNautic!"
      caption="Forgot your password?"
      description="No problem. Just enter your email address and we’ll send you a reset link."
      color="text"
      image={illustrationImage}
      premotto={"INSPIRED BY THE FUTURE:"}
      motto={"DECENTRALIZED SHIPPING PLATFORM"}
    >
      <VuiBox component="form" role="form">
        <VuiBox>
          <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
            Create new password
          </VuiTypography>
        </VuiBox>
        <GradientBorder
          minWidth="100%"
          borderRadius={borders.borderRadius.lg}
          padding="1px"
          backgroundImage={radialGradient(
            palette.gradients.borderLight.main,
            palette.gradients.borderLight.state,
            palette.gradients.borderLight.angle
          )}
        >
          {isShowPassword ? (
            <VuiInput
              type="password"
              placeholder="eg. newpassword"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={newPassword}
              name="password"
              error={errorMsg.password.errorMsg.length > 0}
              onChange={(e) => handleResetInput(e.target.name, e.target.value)}
              // onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <VuiInput
              type="text"
              placeholder="eg. newpassword"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={newPassword}
              name="password"
              error={errorMsg.password.errorMsg.length > 0}
              onChange={(e) => handleResetInput(e.target.name, e.target.value)}
              // onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </GradientBorder>
        <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
          {errorMsg.password.errorMsg}
        </VuiTypography>

        {isShowPassword ? (
          <VuiBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword(false)}>
              <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                Show
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        ) : (
          <VuiBox
            sx={{
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: 500,
              color: "#0075ff",
              marginTop: "2px",
              fontFamily: "Plus Jakarta Display,Helvetica,Arial,sans-serif",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <VuiBox onClick={() => handleShowPassword(true)}>
              <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                Hide
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        )}

        <VuiBox>
          <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
            Confirm password
          </VuiTypography>
        </VuiBox>
        <GradientBorder
          minWidth="100%"
          borderRadius={borders.borderRadius.lg}
          padding="1px"
          backgroundImage={radialGradient(
            palette.gradients.borderLight.main,
            palette.gradients.borderLight.state,
            palette.gradients.borderLight.angle
          )}
        >
          {isShowPassword2 ? (
            <VuiInput
              type="password"
              placeholder="Re-enter password"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={confirmPassword}
              name="confirmPassword"
              error={errorMsg.confirmPassword.errorMsg.length > 0}
              onChange={(e) => handleResetInput(e.target.name, e.target.value)}
              // onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <VuiInput
              type="text"
              placeholder="Re-enter password"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={confirmPassword}
              name="confirmPassword"
              error={errorMsg.confirmPassword.errorMsg.length > 0}
              onChange={(e) => handleResetInput(e.target.name, e.target.value)}
              // onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </GradientBorder>
        <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
          {errorMsg.confirmPassword.errorMsg}
        </VuiTypography>

        {isShowPassword2 ? (
          <VuiBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword2(false)}>
              <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                Show
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        ) : (
          <VuiBox
            sx={{
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: 500,
              color: "#0075ff",
              marginTop: "2px",
              fontFamily: "Plus Jakarta Display,Helvetica,Arial,sans-serif",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <VuiBox onClick={() => handleShowPassword2(true)}>
              <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                Hide
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        )}

        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" variant="gradient" fullWidth onClick={() => handleReset()}>
            Reset my password
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default ResetPassword;
